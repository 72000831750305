/* modal
---------------------------------------------------------- */
.kkrs-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1100;
	@include flex_val_center(nowrap);
	justify-content: center;
	background-color: rgba(#5b5b5b, .9);
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s, visibility .2s;

	&:after {
		@include ico_font("\e911");
		color: #fff;
		font-size: 22px;
		position: fixed;
		width: 80px;
		height: 80px;
		text-align: center;
		line-height: 8px;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	&.kkrs-active {
		opacity: 1;
		visibility: visible;
	}
}

//モーダルコンテンツ
.kkrs-modal-contents {
	background-color: #fff;
	max-width: calc(100% - 160px);

	&__header {
		height: 80px;
		@include flex_val_center(nowrap);
		padding: 15px 40px;
	}

	&__body {
		padding: 0 20px 20px 20px;
		overflow: auto;
		max-height: calc(100vh - 120px); //ヘッダー80px＋上20px下20px分の余白(IE11で表示崩れのため%をやめてvhで指定)
	}

	&__footer {
		//kkrs-modal-contents__body の高さ調整が必要なため、各モーダルのscssで調整
	}

	.kkrs-modal-heading {
		font-size: 24px;
		line-height: 1.2;
		font-weight: 400;
	}

	//ヘッダタイトル左の閉じるボタン
	.kkrs-modal-close-btn {
		display: none; //PC非表示
	}
}

@include mq(sp) {
	.kkrs-modal {
		&:after {
			//PC側でオーバーレイの右上に固定している×ボタンはクリックしても閉じないので非表示
			display: none;
		}
	}

	//モーダルコンテンツ
	.kkrs-modal-contents {
		max-width: 100%;
		width: 100%;
		height: 100%;
		max-height: 100%;
		@include flex(nowrap);
		flex-direction: column;

		&__header {
			height: auto;
			@include flex_val_center(nowrap);
			justify-content: center;
			padding: 15px 50px 15px;
			position: relative;
			border-bottom: 1px solid $color-border-gray;
		}

		&__body {
			padding: 20px;
			max-height: 100%;
		}

		.kkrs-modal-heading {
			font-size: 16px;
		}

		//閉じるボタン
		.kkrs-modal-close-btn {
			display: block;
			width: 50px;
			height: 50px;
			@include abs_tr(50%, 0);
			transform: translateY(-50%);
			color: #95999e;

			&:before {
				@include sp_material_icon("\e5cd");
				font-size: 24px;
				@include abs_tl(50%, 50%);
				transform: translate(-50%, -50%);
			}
		}
	}
}