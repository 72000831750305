//★レスポンシブ★
/* search-big-panel
---------------------------------------------------------- */
//--------------------------------------------
//kkrs-search-big-panel 検索パネル 本体
//--------------------------------------------
.kkrs-search-big-panel {

	//閉じるボタン
	.kkrs-search-big-panel-close-btn {
		width: 40px;
		height: 40px;
		@include abs_tr(0, 0);
		line-height: 1;
		color: #95999e;
		z-index: 10;

		&:before {
			@include material_icon("\e5cd");
			font-size: 18px;
			@include abs_tl(50%, 50%);
			transform: translate(-50%, -50%);
		}
	}

	//クリアボタン
	.kkrs-search-big-panel-clear-btn {
		@include abs_tl(10px, 0);
		color: $color-primary-blue;
		font-size: 13px;
		line-height: 30px;
		padding: 0 15px;
	}

	//決定ボタン
	.kkrs-search-big-panel-fix-btn {
		width: 100%;
		//margin-top: 15px;
		padding: 13px;
		color: #fff;
		font-weight: 700;
		font-size: 18px;
		line-height: 1;
		text-align: center;
		letter-spacing: .1em;
		@include btn-radius;
		@include btn-ripple(#c6edff);
		background: $color-btn;
	}
}

@include mq(sp) {
	.kkrs-search-big-panel {
		position: fixed;
		top: 0;
		right: 100%;
		width: 100%;
		height: 100%;
		visibility: visible;
		z-index: 1000;
		background-color: #fff;
		transition: right .2s;
		overflow-x: hidden;

		&.kkrs-active {
			right: 0;
		}

		&__header {
			height: 50px;
			background: #fff;
			box-shadow: 0 2px 3px 0 rgba($primary_black, .2);
			padding: 10px 50px;
			position: relative;
			overflow: hidden;
			@include flex_val_center(nowrap);
			justify-content: center;
		}

		&__body {
			height: calc(100% - 50px);
			overflow: auto;
			padding: 20px 15px 40px;
		}

		&__footer {
			display: block;
			width: 100%;
			background: #fff;
			height: 78px;
			padding: 15px;
			box-shadow: 0 0 10px 0 rgba($primary_black, .2);
			z-index: 100;
		}

		//パネルタイトル
		.kkrs-search-big-panel-ttl {
			font-size: 18px;
		}

		//閉じるボタン
		.kkrs-search-big-panel-close-btn {
			width: 50px;
			height: 50px;

			&:before {
				font-size: 24px;
			}
		}

		//クリアボタン
		.kkrs-search-big-panel-clear-btn {
			line-height: 50px;
			top: 0;
			left: 0;
		}

		//決定するボタン
		.kkrs-search-big-panel-fix-btn {
			font-weight: 400;
			padding: 15px;
			margin-top: 0;
		}
	}
}

//--------------------------------------------
//kkrs-panel-select-people 部屋数・人数パネル
//--------------------------------------------
@include mq(pc) {
	.kkrs-panel-select-people {
		display: none; //初期非表示
		background-color: #fff;
		min-width: 400px;
		box-shadow: 0 3px 6px 0 rgba(#000, .2);
		@include abs_tl(100%, 0);
		padding: 40px 15px 15px;
		z-index: 10;

		&.kkrs-active {
			display: block;
		}

		.kkrs-search-big-panel-ttl {
			display: none; //PCでは非表示
		}
	}
}

@include mq(sp) {
	.kkrs-panel-select-people {
		.kkrs-search-big-panel__body {
			height: calc(100% - 157px);
			padding-top: 30px;
		}

		.kkrs-search-big-panel__footer {
			height: 107px;
		}
	}
}

//--------------------------------------------
//kkrs-panel-calendar カレンダーパネル
//--------------------------------------------
.kkrs-panel-calendar {

	//出発日・帰着日用アイコン（PCのみ）
	.kkrs-calendar-period__arrow {
		&:before {
			@include material_icon("\e5c8");
		}
	}
}

@include mq(pc) {
	.kkrs-panel-calendar {
		display: none; //初期非表示
		background-color: #fff;
		width: 750px;
		box-shadow: 0 3px 6px 0 rgba(#000, .2);
		@include abs_tl(100%, 0);
		padding: 25px 40px 15px;
		z-index: 10;

		&.kkrs-active {
			display: block;
		}

		.kkrs-search-big-panel-ttl {
			display: none; //PCでは非表示
		}

		//PCは2か月分表示
		.kkrs-calendar-column:nth-child(n + 3) {
			display: none;
		}
	}

	//出発日,帰着日カレンダーの調整
	.kkrs-panel-calendar--period {
		.kkrs-search-big-panel__footer {
			display: none;
		}

		//PCのみ：出発日,帰着日を表示するフッター
		.kkrs-calendar-footer {
			margin-top: 16px;
			padding-top: 16px;
			border-top: 1px solid $line_gray;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.kkrs-calendar-period {
				background: $bg_gray;
				display: flex;
				align-items: center;
				padding: 8px 16px;
				max-width: 400px;
			}

			.kkrs-calendar-period__item {
				width: 128px;
				line-height: 1.5;
			}

			.kkrs-calendar-period__item:last-child {
				margin-left: auto;
				align-self: flex-end;
			}

			.kkrs-calendar-period__display {
				margin: 0;
				padding: 0;
				cursor: pointer;

				&.kkrs-current {
					border-bottom: 3px solid #52C8FF;
				}
			}

			.kkrs-calendar-period__title {
				margin: 0;
				padding: 0;
				font-size: 12px;
				color: $dark_gray;
				margin-bottom: 4px;
			}

			.kkrs-calendar-period__body {
				margin: 0;
				padding: 0;
				font-size: 16px;
			}

			.kkrs-calendar-period__arrow {
				display: flex;
				padding: 0 16px;

				&:before {
					font-size: 24px;
					color: $dark_gray;
				}
			}

			.kkrs-calendar-period__night {
				text-align: right;
				font-size: 16px;
			}

			.kkrs-search-big-panel-fix-btn {
				width: 200px;
				margin: 0;
			}
		}

		//PCのみ：「帰着日を選択してください」メッセージ
		.kkrs-calendar-date-popup {
			position: absolute;
			bottom: calc(100% + 16px);
			left: 50%;
			transform: translateX(-50%);
			font-size: 12px;
			line-height: 1.4;
			color: #fff;
			text-align: center;
			white-space: nowrap;
			padding: 8px;
			background: $primary_black;
			border-radius: 4px;
			box-shadow: 0 1px 4px 0 rgba($primary_black,.25);

			&::before {
				content: "";
				position: absolute;
				bottom: -8px;
				left: 50%;
				margin-left: -8px;
				border-style: solid;
				border-color: $primary_black transparent transparent;
				border-width: 8px 8px 0;
			}
		}
	}

	//チェックイン日・泊数カレンダーの調整
	.kkrs-panel-calendar--checkin {

		//クリアボタン<div>
		.kkrs-search-clear-tooltip {
			@include abs_tl(10px, 0);

			.kkrs-search-big-panel-clear-btn {
				position: static;
				transform: none;
			}

			.kkrs-tooltip-wrap {
				line-height: 1;
				bottom: calc(100% + 5px);
			}
		}

		.kkrs-search-big-panel__footer {
			display: flex;
			align-items: center;
			border-top: 1px solid #bcbdc2;
			padding-top: 18px;
			padding-bottom: 10px;
		}

		.kkrs-selected-date {
			&__checkin {
				display: none;
			}

			&__night {
				margin-left: auto;
				display: flex;
				align-items: center;
			}

			&__night-label {
				font-size: 15px;
				padding-right: 1.5em;
			}
		}

		.kkrs-search-big-panel-fix-btn {
			margin: 0 0 0 auto;
			width: 200px;
		}
	}
}

@include mq(sp) {
	.kkrs-panel-calendar {
		.kkrs-search-big-panel__body {
			height: calc(100% - 182px);
			padding-top: 30px;
		}

		.kkrs-search-big-panel__footer {
			height: 132px;
		}

		//モーダルフッターに選択した出発日,帰着日を表示
		.kkrs-selected-period {
			display: flex;
			background: #f7f7f7;
			margin-bottom: 12px;
			position: relative;

			//矢印（→）を表示
			&:before {
				@include sp_ico_font("\e908");
				@include abs_tl(50%, 50%);
				transform: translate(-50%, -50%);
			}

			input {
				background: transparent;
				border: none;
				border-radius: 0;
				width: 50%;
				height: 42px;
				padding: 0 12px;
			}
		}

		//モーダルフッターに選択したチェックイン日,泊数選択を表示
		.kkrs-selected-date {
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			&__checkin {
				width: 50%;
				background: #f7f7f7;

				input {
					background: transparent;
					border: none;
					border-radius: 0;
					width: 100%;
					height: 42px;
					padding: 0 12px;
				}
			}

			&__night {
				margin-left: auto;
				display: flex;
				align-items: center;
			}

			&__night-label {
				font-size: 13px;
				padding-right: 1em;
			}
		}
	}

	//出発日,帰着日カレンダーの調整
	.kkrs-panel-calendar--period {

		//PCのみ：出発日,帰着日を表示するフッター
		.kkrs-calendar-footer {
			display: none;
		}

		//PCのみ：「帰着日を選択してください」メッセージ
		.kkrs-calendar-date-popup {
			display: none;
		}
	}

	//チェックイン日・泊数カレンダーの調整
	.kkrs-panel-calendar--checkin {

		//クリアボタン<div>
		.kkrs-search-clear-tooltip {
			@include abs_tl(50%, 15px);
			transform: translateY(-50%);

			.kkrs-tooltip-wrap {
				//SPはツールチップではなくモーダルを表示
				display: none;
			}
		}

		//クリアボタン
		.kkrs-search-big-panel-clear-btn {
			position: static;
			transform: none;
			padding: 0;
		}
	}
}

//--------------------------------------------
//kkrs-panel-area 出発地/目的地パネル（ガジェットでは非表示）
//--------------------------------------------
.kkrs-panel-area {
	display: none !important;
}