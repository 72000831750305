/* select
---------------------------------------------------------- */
//
//kkrs-panel-select-people モーダル内 人数・部屋数
//--------------------------------------------
.kkrs-panel-select-people {

	//注意文言
	.kkrs-select-people-att {
		margin-top: 10px;
		padding-top: 14px;
		font-size: 11px;
		border-top: 1px solid $color-border-gray;

		>p {
			text-indent: -1em;
			padding-left: 1em;
		}
	}

	//旅行人数,室数
	.kkrs-select-guest,
	.kkrs-select-room {
		display: flex;
		line-height: 1.4;
		padding: 0 5px;

		&__label {
			width: 9em;
			flex-shrink: 0;
			padding-top: 4px;
			font-size: 16px;
			line-height: 1.1;

			small {
				display: inline;
				font-size: 13px;
				margin-left: 1em;
			}
		}

		&__list {
			//-webkit-flex: 1;
			//flex: 1; IEでパネルの幅をボックスより大きくしたときにきかないので使わない
			width: calc(100% - 9em);
		}

		&__item {
			display: flex;
			align-items: center;
			margin-bottom: 18px;
		}

		&__type {
			font-size: 13px;
		}

		.kkrs-spinbox {
			margin-left: auto;
		}

		//アラート
		.kkrs-alert-txt {
			text-align: right;
			color: $color-jtb-red;
			font-size: 12px;
			margin-bottom: .5em;
		}
	}

	//室数
	.kkrs-select-room {
		border-bottom: 1px solid $color-border-gray;
		margin: 18px 0;
	}

	//こども人数アコーディオン（PCのみ）
	.kkrs-select-expand-trigger {
		padding: 0 5px;
		display: none;

		@include mq(pc) {
			display: block;
		}

		&__btn {
			display: inline-block;
			font-size: 14px;
			color: $color-link;
			cursor: pointer;
			position: relative;
			padding: 0 2em 0 0;

			&:after {
				@include material_icon("\e5cf");
				color: $dark_gray;
				font-size: 20px;
				font-weight: 400;
				position: absolute;
				top: calc(50% - .5em);
				right: 0;
				transition: transform .2s;
			}

			&.kkrs-active {
				&:after {
					transform: rotate(180deg);
				}
			}
		}
	}

	@include mq(pc) {
		.kkrs-select-expand-content {
			opacity: 0;
			height: 0;
			visibility: hidden;
			transition: padding .2s, opacity .2s;

			.kkrs-select-people-att {
				border-top: none;
				padding-top: 0;
			}

			&.kkrs-active {
				opacity: 1;
				height: auto;
				visibility: visible;
				padding: 20px 0 0;
			}
		}
	}

	//選択中の人数
	.kkrs-select-people-number {
		text-align: center;
		font-size: 14px;
		line-height: 1.5;
		margin-top: 15px;
		margin-bottom: 8px;
	}
}

//
//kkrs-select-people パネル内 人数・部屋数
//kkrs-select-night パネル内 泊数
//--------------------------------------------
.kkrs-search-panel {
	.kkrs-select-night {
		padding: 10px 10px 0 10px;
	}

	//注意文言
	.kkrs-select-days-att {
		margin-top: 5px;
		padding-top: 10px;
		font-size: 11px;
		border-top: 1px solid $color-border-gray;

		>p {
			text-indent: -1em;
			padding: 0 0 0 1em;
			margin: 0;
		}
	}

	//泊数
	.kkrs-select-days {
		@include flex_val_top(nowrap);
		line-height: 1.4;

		&__label {
			margin: 0;
			padding: 5px 0 0;
		}

		&__list {}

		&__item {
			@include flex_val_center(nowrap);
			margin-bottom: 10px;
		}

		&__age {
			font-size: 12px;
			margin: 0;
			padding: 0;
		}

		.kkrs-spinbox {
			margin-left: auto;
		}

		//アラート
		.kkrs-alert-txt {
			text-align: right;
			color: $color-jtb-red;
			font-size: 12px;
			margin-bottom: 5px;
		}
	}

	//泊数
	.kkrs-select-days {
		&__label {
			width: 4em;
		}

		&__list {
			//-webkit-flex: 1;
			//flex: 1; IEでパネルの幅をボックスより大きくしたときにきかないので使わない
			width: calc(100% - 4em);
		}
	}
}

//
//kkrs-selected-area パネル内 人気都市
//--------------------------------------------
.kkrs-search-panel {
	.kkrs-select-area {
		width: 600px;
		padding: 10px 10px 0 10px;
	}

	//タイトル
	.kkrs-select-area-ttl {}

	//タブ
	.kkrs-select-area-tab {
		border-bottom: 1px solid $color-border-gray;

		&__list {
			@include flex(nowrap);
			margin: 0;
			padding: 0;

			>li {
				margin: 0;
				padding: 10px 0;
				color: #999;
				cursor: pointer;

				&:not(:last-child) {
					margin-right: 1.5em;
				}

				&.kkrs-active {
					color: $color-primary-blue;
					font-weight: bold;
					position: relative;

					&::after {
						content: "";
						background: $color-btn;
						width: 100%;
						height: 3px;
						position: absolute;
						bottom: -1px;
						left: 0;
					}
				}
			}
		}
	}

	//選択エリア
	.kkrs-select-area-contents {
		padding-top: 10px;
	}

	//エリアリスト
	.kkrs-select-area-list {
		@include flex(wrap);
		margin: 0;
		padding: 0;

		>li {
			width: 50%;
			cursor: pointer;
			padding: .5em;
			margin: 0;
			line-height: 1.4;
			@include hover-opacity;
		}

		&.kkrs-col3>li {
			width: 33.3333%;
		}

		&.kkrs-col4>li {
			width: 25%;
		}

		&.kkrs-col5>li {
			width: 20%;
		}

		&.kkrs-col6>li {
			width: 16.6666%;
		}
	}
}

@include mq(sp) {

	//
	//kkrs-panel-select-people モーダル内 人数・部屋数
	//--------------------------------------------
	.kkrs-panel-select-people {

		//注意文言
		.kkrs-select-people-att {
			margin-top: 0;
			padding-top: 20px;
			font-size: 12px;
		}

		//旅行人数,室数,泊数
		.kkrs-select-guest,
		.kkrs-select-room {
			padding: 0;

			&__label {
				font-weight: 700;

				small {
					font-size: 14px;
					font-weight: 400;
				}
			}

			&__list {
				flex: 1;
			}

			&__item {
				margin-bottom: 32px;
			}

			&__type {
				font-size: 14px;
			}
		}

		//室数
		.kkrs-select-room {
			margin: 0 0 20px;

			&__item {
				margin-bottom: 20px;
			}
		}

		//選択中の人数
		.kkrs-select-people-number {
			margin-top: 0;
		}
	}

	//
	//kkrs-select-night パネル内 泊数
	//--------------------------------------------
	.kkrs-search-panel {
		.kkrs-select-night {
			padding: 10px 0 0;
		}

		//泊数
		.kkrs-select-days {
			padding: 0 5px;

			&__label {
				width: 4em;
				padding-right: 10px;
			}

			&__item {}

			&__age {}

			.kkrs-spinbox {
				margin-left: auto;
			}

			//アラート
			.kkrs-alert-txt {}
		}
	}

	//
	//kkrs-selected-area パネル内 人気都市 
	//--------------------------------------------
	.kkrs-search-panel {
		.kkrs-select-area {
			padding: 0;
			width: auto;
		}

		//タイトル
		.kkrs-select-area-ttl {
			display: none;
		}

		//タブ
		.kkrs-select-area-tab {
			&__list {
				//-webkit-justify-content: space-between;
				//justify-content: space-between;
				white-space: nowrap;
				overflow-x: scroll;
				-webkit-overflow-scrolling: touch;

				>li {
					white-space: nowrap;

					&:not(:last-child) {
						margin-right: 1em;
					}
				}
			}
		}

		//選択エリア
		.kkrs-select-area-contents {}

		//エリアリスト
		.kkrs-select-area-list {
			>li {}

			&.kkrs-col3>li,
			&.kkrs-col4>li {
				width: 50%;
			}

			&.kkrs-col5>li,
			&.kkrs-col6>li {
				width: 33.3333%;
			}

			&.kkrs-sp-col1>li {
				width: 100%;
			}
		}
	}
}