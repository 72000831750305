/* spinbox
---------------------------------------------------------- */
.kkrs-spinbox {
	position: relative;
	width: 110px;
	min-width: 110px;
	padding: 0 40px;

	&__number {
		text-align: center;
		font-size: 20px;
		line-height: 28px;
	}

	%spinbtn {
		background: $primary_blue;
		color: #fff;
		border-radius: 2px;
		line-height: 1;
		width: 28px;
		height: 28px;
		position: absolute;
		top: 0;
		transition: opacity .2s;

		&:before {
			font-size: 14px;
			font-weight: bold;
			@include abs_tl(50%, 50%);
			transform: translate(-50%, -50%);
		}

		&:hover {
			opacity: .8;
		}

		&:active {
			box-shadow: 0 0 10px 0 rgba(#000, .5);
			opacity: 1;
		}

		&.kkrs-disabled {
			background: #e1e1e1;
			pointer-events: none;
		}
	}

	&__btn-minus {
		@extend %spinbtn;
		left: 0;

		&:before {
			@include ico_font("\e915");
		}
	}

	&__btn-plus {
		@extend %spinbtn;
		right: 0;

		&:before {
			@include ico_font("\e900");
		}
	}

	//アラート位置調整
	.kkrs-alert-popup {
		left: auto;
		right: 0;
		transform: none;

		&:before,
		&:after {
			left: auto;
			transform: none;
			right: 48px;
		}
	}
}