/* calendar
---------------------------------------------------------- */
//ベースレイアウト
.kkrs-calendar-slide {
	@include flex(nowrap);
}

.kkrs-calendar-column {
	width: 50%;
	padding: 10px;
	position: relative;
	text-align: center;
}

.kkrs-calendar-header {
	font-size: 16px;
	line-height: 1;
	font-weight: 400;
	padding-bottom: 10px;

	em {
		font-size: 20px;
		font-weight: 400;
		font-style: normal;
	}
}

.kkrs-calendar-tbl {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;

	thead {
		th {
			font-size: 12px;
			text-align: center;
			vertical-align: middle;
			padding: 10px 0;
		}
	}

	tbody {
		td {
			font-size: 16px;
			height: 40px;
			vertical-align: middle;
			padding: 0;
		}
	}
}

//各要素
.kkrs-calendar {
	position: relative;

	&__date {
		position: relative;

		a {
			display: block;
			text-decoration: none;
			color: $color-txt-normal;
			width: 40px;
			height: 40px;
			border-radius: 40px;
			line-height: 40px;
			margin: auto;
			transition: background .2s;

			//searchPanel(SP) カレンダーの縦一列の日付が全てdisabledだと幅が狭くなる
			//独自にspanでラップするようにしてwidthを合わせる
			span {
				display: block;
				width: 40px;
			}

			&:hover {
				background: $color-light-blue;
				opacity: 1;
			}
		}

		&.kkrs-disabled {
			color: #c0c5cb;

			a {
				pointer-events: none;
				color: #c0c5cb;
			}
		}

		&.kkrs-active {
			a {
				background: $color-btn;
				color: #fff;
			}
		}

		&.kkrs-checkin {
			background: linear-gradient(90deg, rgba(#d0effe, 0) 0%, rgba(#d0effe, 0) 50%, rgba(#d0effe, 1) 50%, rgba(#d0effe, 1) 100%);

			a {
				background: $color-btn;
				color: #fff;
			}
		}

		&.kkrs-checkout {
			background: linear-gradient(90deg, rgba(#d0effe, 1) 0%, rgba(#d0effe, 1) 50%, rgba(#d0effe, 0) 50%, rgba(#d0effe, 0) 100%);

			a {
				background: $color-btn;
				color: #fff;
			}
		}

		&.kkrs-period {
			background: #d0effe;
		}

		//チェックイン日選択 最終日
		&.kkrs-period-last:not(.kkrs-checkin) {
			background: linear-gradient(90deg, rgba(#d0effe, 1) 0%, rgba(#d0effe, 1) 50%, rgba(#d0effe, 0) 50%, rgba(#d0effe, 0) 100%);

			a {
				background: #d0effe;
				border-radius: 40px;
			}
		}

		//チェックイン日選択 1泊の場合（チェックイン日、最終日）
		&.kkrs-checkin.kkrs-period-last {
			background: none;
		}
	}
}

.kkrs-saturday {
	color: $primary_blue;

	a {
		color: $primary_blue;
	}
}

.kkrs-sunday {
	color: $secondary_red;

	a {
		color: $secondary_red;
	}
}

.kkrs-holiday {
	color: $secondary_red;

	a {
		color: $secondary_red;
	}
}

//ナビゲーション
.kkrs-calendar-prev,
.kkrs-calendar-next {
	position: absolute;
	top: 50%;
	cursor: pointer;
	line-height: 1;
	transition: opacity .2s;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-50%);

	&:before {
		@include material_icon("\e5e1");
		font-size: 16px;
		font-weight: 700;
		color: $dark_gray;
	}

	&:hover {
		opacity: .8;
	}

	&.kkrs-disabled {
		opacity: .2;
		pointer-events: none;
	}
}

.kkrs-calendar-prev {
	left: -40px;

	&:before {
		transform: scale(-1, 1);
	}
}

.kkrs-calendar-next {
	right: -40px;
}

@include mq(sp) {
	.kkrs-calendar-slide {
		display: block;
	}

	.kkrs-calendar-navi {
		display: none;
	}

	.kkrs-calendar-column {
		width: 100%;
		padding: 0;

		&:nth-child(n+2) {
			display: block;
		}

		&:not(:first-child) {
			margin-top: 20px;
			padding-top: 20px;
			border-top: 1px solid #c0c5cb;
		}
	}

	.kkrs-calendar-header {
		padding: 10px 0;
	}

	//ナビゲーション
	.kkrs-calendar-prev,
	.kkrs-calendar-next {
		top: 0;
		width: auto;
		height: auto;
		transform: translateY(0);

		.kkrs-calendar-prev {
			left: 5px;
		}

		.kkrs-calendar-next {
			right: 5px;
		}
	}
}