/* tooltip
---------------------------------------------------------- */
.kkrs-tooltip {
	position: relative;
	cursor: pointer;

	&:hover {
		.kkrs-tooltip-wrap {
			//display: block;
			visibility: visible;
			opacity: 1;
		}
	}
}

.kkrs-tooltip-right {
	//右に矢印
	@extend .kkrs-tooltip;

	.kkrs-tooltip-wrap {
		left: auto;
		right: 0;

		&:before {
			right: 30px;
			left: auto;
		}

		&:after {
			right: 29px;
			left: auto;
		}
	}
}

.kkrs-tooltip-btm {
	//右下に表示
	@extend .kkrs-tooltip;

	.kkrs-tooltip-wrap {
		left: auto;
		bottom: auto;
		top: calc(100% + 10px);
		right: 0;

		&:before {
			border-width: 8px 8px 10px 8px;
			border-color: transparent transparent #fff transparent;
			top: auto;
			left: auto;
			bottom: 100%;
			right: 30px;
		}

		&:after {
			border-width: 9px 9px 11px 9px;
			border-color: transparent transparent $line_gray transparent;
			top: auto;
			left: auto;
			bottom: 100%;
			right: 29px;
		}
	}
}

.kkrs-tooltip-btm-left {
	//左下に表示
	@extend .kkrs-tooltip;

	.kkrs-tooltip-wrap {
		bottom: auto;
		top: calc(100% + 10px);
		left: 0;

		&:before {
			border-width: 8px 8px 10px 8px;
			border-color: transparent transparent #fff transparent;
			top: auto;
			bottom: 100%;
		}

		&:after {
			border-width: 9px 9px 11px 9px;
			border-color: transparent transparent $line_gray transparent;
			top: auto;
			bottom: 100%;
		}
	}
}

.kkrs-tooltip-wrap {
	background-color: #fff;
	font-size: 12px;
	line-height: 1.6;
	border: 1px solid $line_gray;
	padding: 10px;
	box-shadow: 0 3px 6px 0 rgba(#000, .2);
	@include abs_bl(calc(100% + 10px), -22px);
	white-space: nowrap; //何度か削除したがやはりないとアイコンの場合がおかしい。必要なら個別にwidth設定する
	//display: none;
	z-index: 102; //11から変更（ヘッダーにかぶるので）
	transition: all .2s;
	visibility: hidden;
	opacity: 0;
	text-align: left;

	&:before {
		content: "";
		border-style: solid;
		border-width: 10px 8px 8px 8px;
		border-color: #fff transparent transparent transparent;
		@include abs_tl(100%, 30px);
		z-index: 2;
	}

	&:after {
		content: "";
		border-style: solid;
		border-width: 11px 9px 9px 9px;
		border-color: $line_gray transparent transparent;
		@include abs_tl(100%, 29px);
		z-index: 1;
	}
}

//ツールチップ内の基本タイトル、テキスト
.kkrs-tooltip-wrap {
	.kkrs-tooltip-ttl {
		font-weight: 400;
		margin-bottom: .5em;
	}

	.kkrs-tooltip-txt {
		font-weight: 400;
	}
}

//ヘルプアイコンをクリックしてツールチップを表示
[class*="kkrs-tooltip"] {
	&.kkrs-help {
		display: inline-block;

		&:before {
			@include ico_font("\e96a");
			color: #a9b2b8;
		}
	}
}