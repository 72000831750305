/* modal-confirm
---------------------------------------------------------- */
.kkrs-modal-confirm {

	//overlayの×ボタン
	&:after {
		display: none;
	}

	.kkrs-modal-contents {
		max-width: 560px;
		box-shadow: 0 0 5px 0 rgba($primary_black, .08);
		border-radius: 10px;
		position: relative;
	}

	.kkrs-modal-close-btn {
		border: none;
		background: transparent;
		@include abs_tr(0, 0);
		width: 60px;
		height: 60px;
		line-height: 1;
		color: #95999e;
		z-index: 10;
		display: block;

		&:before {
			@include material_icon("\e5cd");
			font-size: 26px;
		}
	}

	.kkrs-confirm-wrap {
		padding: 60px 50px 50px;
	}

	.kkrs-confirm-txt {
		text-align: center;
		font-size: 18px;
		margin-bottom: 1.5em;
	}

	.kkrs-confirm-navi {
		@include flex_al_between(nowrap);
	}

	.kkrs-ok-btn {
		background: #fff;
		width: 55%;
		border: 2px solid #95999e;
		border-radius: 3px;
		height: 50px;
		text-align: center;
		font-size: 16px;
		font-weight: 700;
	}

	.kkrs-cancel-btn {
		border: none;
		width: calc(45% - 20px);
		display: block;
		border-radius: 3px;
		background: #e1e1e1;
		height: 50px;
		text-align: center;
		font-size: 16px;
		color: $dark_gray;
		font-weight: 700;
	}
}

//----------------------------------------------------------
//レスポンシブ
//----------------------------------------------------------
@include mq(sp) {
	.kkrs-modal-confirm {
		.kkrs-modal-contents {
			width: 80%;
			height: auto;
			border-radius: 3px;
		}

		.kkrs-modal-close-btn {
			width: 40px;
			height: 40px;
			transform: none;
		}

		.kkrs-confirm-wrap {
			padding: 40px 20px 30px;
		}

		.kkrs-confirm-txt {
			font-size: 16px;
		}

		.kkrs-ok-btn {
			font-size: 16px;
		}

		.kkrs-cancel-btn {
			font-size: 16px;
		}
	}
}