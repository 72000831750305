/* suggest
---------------------------------------------------------- */
.kkrs-suggest-list {
	max-height: 200px;
	overflow-y: auto;
	padding: 0;
	margin: 0;

	>li {
		margin-bottom: 2px;
		padding: 5px 10px 5px 22px;
		margin: 0;
		cursor: pointer;
		position: relative;
		@include hover-opacity;
		max-width: 600px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		em {
			color: $color-jtb-red;
		}

		&:before {
			@include abs_tl(50%, 0);
			margin-top: -.45em; //少しずらす
		}

		&.kkrs-child {
			line-height: 1.8; //childでない<li>要素と高さを合わせる
			padding-left: 42px;

			&:before {
				@include ico_font("\e968");
				@include abs_tl(50%, 22px);
				margin-top: -.5em;
				color: #999;
			}
		}

		//各アイコン----------
		//エリア
		&.kkrs-area:before {
			@include ico_font("\e961");
		}

		//空港
		&.kkrs-airport:before {
			@include ico_font("\e918");
		}

		//駅
		&.kkrs-station:before {
			@include ico_font("\e969");
		}

		//ランドマーク
		&.kkrs-landmark:before {
			@include ico_font("\e973");
		}
	}
}

//候補なし
.kkrs-suggest .kkrs-notfound {
	color: #999;
}

@include mq(sp) {
	.kkrs-suggest-list {
		>li {
			max-width: initial;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}