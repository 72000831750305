/* alert
---------------------------------------------------------- */
//アラート--------------
.kkrs-alert-txt {
	@include kkrs-alert-txt;
	margin-bottom: 1em;
	text-align: left;
}

.kkrs-alert-box {
	@include kkrs-alert-box;
	margin-bottom: 1em;

	.kkrs-alert-txt {
		margin-bottom: 0;
	}
}

//SP 画面下部固定アラート ×ボタン付き
.kkrs-alert-box--sp-popup {
	margin-top: 10px;
	margin-bottom: 0;

	@include mq(sp) {
		background: $light_red;
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1001;
		padding: 20px 30px 20px 10px;
		margin-bottom: 0;
	}

	.kkrs-alert-box-close-btn {
		@include mq(pc) {
			display: none !important;
		}

		width: 36px;
		height: 36px;
		@include abs_tr(0, 0);
		color: $dark_gray;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			@include material_icon("\e5cd");
			font-size: 20px;
		}
	}
}

//アラート ポップアップタイプ--------------
.kkrs-alert-popup {
	background: #fff;
	border: 1px solid $color-jtb-secondary-red;
	//box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
	color: $color-jtb-secondary-red;
	display: block;
	font-size: 14px;
	line-height: 1.2;
	position: absolute;
	top: calc(100% + 4px);
	left: 50%;
	transform: translateX(-50%);
	padding: .6em .8em;
	text-align: left;
	z-index: 20;
	max-width: 280px;
	min-width: 180px;
	width: max-content;
	white-space: normal;

	@include mq(sp) {
		font-size: 12px;
		max-width: 100%;
	}

	&:before,
	&:after {
		content: "";
		border-style: solid;
		border-width: 0 6px 8px 6px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}

	&:before {
		border-color: transparent transparent $color-jtb-secondary-red transparent;
		top: calc(0% - 8px);
		z-index: 0;
	}

	&:after {
		border-color: transparent transparent #fff transparent;
		top: calc(0% - 6px);
		z-index: 1;
	}
}