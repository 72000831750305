/* error
---------------------------------------------------------- */
//--------------------------------------------------
//選択ボックスのエラー
//--------------------------------------------------
.kkrs-search-column.kkrs-error {
	@extend %errorPanel;

	.kkrs-search-input {
		background-color: $color-bg-error;
	}
}

%errorPanel {
	&:before {
		//エラー文言がはいる
		background: #fff;
		color: $color-jtb-red;
		display: block;
		font-size: 12px;
		line-height: 1;
		position: absolute;
		top: 100%;
		left: 0;
		text-align: center;
		z-index: 8;
	}

	// &:after {
	// 	//吹き出しのやじるし部分
	// 	content: "";
	// 	border-style: solid;
	// 	border-width: 0 6px 8px 6px;
	// 	border-color: transparent transparent $color-jtb-red transparent;
	// 	@include abs_tl(calc(100% - 4px), 3.5rem);
	// 	z-index: 9;
	// }
}

.kkrs-error-type-01 {
	&:before {
		content: "必須項目です";
	}
}

.kkrs-error-type-02 {
	&:before {
		content: "項目から選択してください";
	}
}

.kkrs-error-type-03 {
	&:before {
		content: "旅行期間が長すぎます。期間は14日間以下で選択してください。";
	}
}